<template>
    <auth-layout :title="$t('application.title')" :button-text="$t('application.button')" :action="submit">
        <validation-provider name="full_name" rules="required|full_name" v-slot="{ errors, reset }" slim>
            <input-group :errors="errors" :label="$t('validation.names.full_name')" :description="$t('application.descriptions.full_name')" required>
                <input-component v-model="form.full_name" :placeholder="$t('validation.names.full_name')" @input="reset" @mousedown.stop />
            </input-group>
        </validation-provider>

        <validation-provider name="email" rules="required|email" v-slot="{ errors, reset }" slim>
            <input-group :errors="errors" :label="$t('validation.names.email')" :description="$t('application.descriptions.email')" required>
                <input-component v-model="form.email" :placeholder="$t('validation.names.email')" @input="reset" @mousedown.stop />
            </input-group>
        </validation-provider>

        <validation-provider name="website" rules="required|url" v-slot="{ errors, reset }" slim>
            <input-group :errors="errors" :label="$t('validation.names.website')" :description="$t('application.descriptions.website')" required>
                <input-component v-model="form.website" :placeholder="$t('validation.names.website')" @input="reset" @mousedown.stop />
            </input-group>
        </validation-provider>

        <validation-provider name="contacts" rules="required" v-slot="{ errors, reset }" slim>
            <input-group :errors="errors" :label="$t('validation.names.contacts')" :description="$t('application.descriptions.contacts')" required>
                <input-component v-model="form.contacts" :placeholder="$t('validation.names.contacts')" @input="reset" @mousedown.stop />
            </input-group>
        </validation-provider>

        <template v-if="!isAuthenticated" #links>
            <router-link :to="{ name: 'login' }">{{ $t('application.login') }}</router-link>
        </template>
    </auth-layout>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { ValidationProvider } from 'vee-validate';
    import { application } from '@/services/skinsPaymentApi';
    import AuthLayout from '@/pages/layout/AuthLayout';
    import InputComponent from '@/components/inputs/InputComponent';
    import InputGroup from '@/components/inputs/InputGroup';

    export default {
        name: 'Application',
        components: {
            AuthLayout,
            InputComponent,
            InputGroup,
            ValidationProvider,
        },
        computed: {
            ...mapGetters('auth', ['isAuthenticated']),
        },
        methods: {
            submit(recaptchaToken) {
                return application({ ...this.form, 'g-recaptcha-response': recaptchaToken }).then(() => {
                    Object.keys(this.form).forEach(key => (this.form[key] = null));
                    this.$toast.success(this.$t('application.success'));
                });
            },
        },
        data() {
            return {
                form: {
                    full_name: null,
                    email: null,
                    website: null,
                    contacts: null,
                },
            };
        },
    };
</script>
